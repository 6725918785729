<template>
  <v-card>
    <v-card-title>
      Tissue Distribution Status
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="getDaskboard"
      fixed-header
       height="300">

    </v-data-table>
  </v-card>
</template>

<script>

import daskboardserice from '@/service/Dashboard.service'

export default {
  data() {
    const statusColor = {
      /* eslint-disable key-spacing */
      Current: 'primary',
      Professional: 'success',
      Rejected: 'error',
      Resigned: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Tissue ID', value: 'tissue_id' },
        { text: 'Blood Report', value: 'blood_report' },
        { text: 'Quarntine', value: 'Quarntine' },
        { text: 'Approval', value: 'approval' },
        { text: 'Distributed Outcome', value: 'distributed_outcome' },
  
      ],
      getDaskboard:[],
    
      status: {
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      statusColor,

      
    }
  },
  watch: {
    options: {
      handler() {
        this.ViewDaskBoard()
      },
      deep: true,
    },
  },
  async mounted() {
    this.ViewDaskBoard()
  },
  methods:{
    async ViewDaskBoard(){
      const service = new daskboardserice()
      let response = await service.getDaskboard()
       if (response) {
       this.getDaskboard = response.TissueResult
        // console.log("Hello Dataaaaaaa",this.getDaskboard)
        this.totallist = this.getDaskboard.length
    
      } else {
        this.getDaskboard = []
        this.totallist = 0
      }
    },
  }
}
</script>
