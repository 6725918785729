<template>
  <v-card class="rounded-b-0">
    <v-card-title class="align-start">
      <span>Hospital Section Wise Division</span>

      <v-spacer></v-spacer>

     
    </v-card-title>

    <v-card-text>
      
     
       <div id="pie-chart"></div>
     
    </v-card-text>
  </v-card>
</template>

<script>

import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import daskboardserice from '@/service/Dashboard.service'
import moment from 'moment'
export default {

  setup() {
    


    return {

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
   
   
   watch: {
    options: {
      handler() {
        this.chartMap()
      },
      deep: true,
    },
  },
  mounted() {
    this.chartMap()

  },
  methods:{

    async chartMap(){
      const service = new daskboardserice()
      let response = await service.getDaskboard()
      var data12 = response.Hospital_Sector_Wise
      var CDS = data12.CDS
      var government_hospital = data12.government_hospital
      var private_hospital = data12.private_hospital
      const arr =[]
     
      arr[0]=government_hospital
      arr[1]=private_hospital
      arr[2]=CDS
    
      var options = {
        series: arr,
        chart: {
          width: 450,
          type: 'pie',
        },
        colors:['#1bbc9b', '#00ffff', '#feb019'],
        legend: { show: false },
        labels: ['Government Hospital', 'Private Hospital ', 'CDS'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 250
            },
          }
        }]
        };

        var pir_chart = new ApexCharts(document.querySelector("#pie-chart"), options);
        pir_chart.render();
    }
  }
}
</script>
